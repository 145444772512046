.Main {
    text-align: center;
    background: linear-gradient(rgba(188,58,98,255),rgba(231,109,115,255), rgba(254,135,124,255));
    min-height: 100%;
    min-width: 100%;
    width: auto;
    height: auto;
    position: fixed;
  }
  

  .Main-header {
    margin-top:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    font-size: 50px;
    color: white;
  }

  .Main-body{

    font-size: 50px;
    color: white;

  }

  .left-paragraph{
    left: 2%;
    color: white;
    position: fixed;
    text-align: left;
  }
  
  
  .laptop {
    position: fixed;
    z-index: -5;
    top: 50%;
    left: 50%;
    width: 65%;
    height: 65%;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(.9);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.laptop #image {
    position: fixed;
    z-index: -5;
    top: 44%;
    left: 95%;
    width: 52%;
    padding-top: 32%;
    transform: translate(-50%, -50%);
    background-size: cover;
    background-image: url('../public/peoplefindersample.png')
}
.laptop img {
    position: fixed;
    z-index: -5;
    top: 47%;
    left: 95.25%;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
    
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
